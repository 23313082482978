const input = {
    username: 'Nome utente',
    password: 'Password',
    email: 'Email',
    phone_number: 'Numero di telefono',
    confirm_password: 'Conferma password',
    search_region_code: 'Codice regione di ricerca',
    backup_code: 'Codice di backup',
};
export default Object.freeze(input);
